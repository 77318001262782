var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-5"},[_c('v-col',{staticClass:"text-h6 black--text pb-0",attrs:{"cols":"12"}},[_vm._v(" Solicitações ")]),_c('v-col',{staticClass:"subtitle-2 grey--text pt-0",attrs:{"cols":"12"}},[_vm._v(" Lista de solicitações recebidas ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0 rounded-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"mb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Buscar por n° de solicitação","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('excel',{attrs:{"button_name":"Exportar","icon":"mdi-download","file_name":"solicitação_de_serviços.xls","data":_vm.requests,"json_fields":_vm.json_fields_services}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.requests,"search":_vm.search,"loading":!_vm.requests,"item-key":"id","custom-filter":_vm.filterOnlyNumber},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'AdminViewRequest',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.$router.push({
                name: 'AdminViewRequest',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:('color: ' + item.status.color),on:{"click":function($event){return _vm.$router.push({
                name: 'AdminViewRequest',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_vm._v(" "+_vm._s(item.status.text)+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"height":"30","dense":""},on:{"click":function($event){return _vm.$router.push({
                name: 'AdminViewRequest',
                params: {
                  id: item.id,
                  data: item,
                },
              })}}},[_vm._v("Ver detalhes")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row class="pa-5">
    <v-col cols="12" class="text-h6 black--text pb-0"> Solicitações </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Lista de solicitações recebidas
    </v-col>
    <v-col cols="12">
      <v-card outlined class="elevation-0 rounded-0">
        <v-card-title class="mb-2">
          <v-row>
            <v-col cols="12" sm="8" md="10">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar por n° de solicitação"
                dense
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <excel
                button_name="Exportar"
                icon="mdi-download"
                file_name="solicitação_de_serviços.xls"
                :data="requests"
                :json_fields="json_fields_services"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="requests"
          :search="search"
          :loading="!requests"
          item-key="id"
          :custom-filter="filterOnlyNumber"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'AdminViewRequest',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <div
              @click="
                $router.push({
                  name: 'AdminViewRequest',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.date }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              class="font-weight-bold"
              :style="'color: ' + item.status.color"
              @click="
                $router.push({
                  name: 'AdminViewRequest',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
            >
              {{ item.status.text }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              height="30"
              dense
              @click="
                $router.push({
                  name: 'AdminViewRequest',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
              class="primary"
              >Ver detalhes</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Excel from "../../../components/Excel/Excel.vue";

export default {
  components: {
    Excel,
  },
  data: () => ({
    search: "",
    requests: [],
    json_fields_services: {
      Id: "id",
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.toString().split(" ").length; i++) {
            textFormatted += `${e.toString().split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Assunto: "subject",
      Status: "status.text",
      Prioridade: "priority",
      Descrição: {
        field: "description",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.toString().split(" ").length; i++) {
            textFormatted += `${e.toString().split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Resposta: {
        field: "answer",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.toString().split(" ").length; i++) {
            textFormatted += `${e.toString().split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
    },
  }),
  mounted() {
    this.showRequests();
  },
  methods: {
    async showRequests() {
      try {
        const response = await this.$axios("service_requests");

        if (response.data) {
          response.data.map((service) => {
            this.requests = [
              {
                id: service.id,
                date: this.formatLastUpdate(service.updated_at),
                status: this.setStatus(service.status),
                priority: service.priority,
                description: service.description,
                subject: service.subject,
                answer: service.service_request_response
                  ? { answer: service.service_request_response.answer }
                  : { answer: "" },
                doctor_id: service.doctor_id,
                // file: service.file ? service.file : null
              },
              ...this.requests,
            ];
          });
        }
      } catch (error) {
        console.log("Get requests error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar receber as solicitações de serviços.",
        });
      }
    },
    setStatus(value) {
      const status = {
        opened: {
          text: "Em aberto",
          value: "opened",
          color: "#feda15",
        },
        under_analysis: {
          text: "Em análise",
          value: "under_analysis",
          color: "#096CC8",
        },
        concluded: {
          text: "Concluída",
          value: "concluded",
          color: "#00AD45",
        },
        closed: {
          text: "Cancelada",
          value: "closed",
          color: "#CF4444",
        },
      };

      return (
        status[value] || {
          text: "Em aberto",
          color: "#feda15",
        }
      );
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    filterOnlyNumber(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "number" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Nº da solicitação",
          value: "id",
          width: 250,
          align: "center",
        },
        { text: "Data", value: "date", width: 250, align: "center" },
        { text: "Status", value: "status", width: 250, align: "center" },
        { text: "Ação", value: "action", width: 250, align: "center" },
      ];
    },
  },
};
</script>

<style></style>
